import React from 'react';
import Map from '../Map';

export const addressComponents = [
    '1903 Forestry Farm Park Drive',
    'Saskatoon, SK S7S 1G9',
    '306-975-3382',
];

export const websiteLink = <a href='https://www.saskatoon.ca/parks-recreation-attractions/events-attractions/saskatoon-forestry-farm-park-zoo'>Forestry Farm Website</a>;
const Venue = () => {
    
    return (
        <div className='tab-inner-content' id='venue'>
            <h1>Venue</h1>
            <h2>Saskatoon Forestry Farm</h2>
            <p>Weather permitting, the ceremony will take place outdoors in the Wedding Garden, with a reception 
                to follow inside the Hall.
            </p>

            <p>The zoo is open until 5:00 pm if you would like to visit, and there are also parks with playgrounds, fishing, and gardens nearby.</p>

            🚭🚭🚭
            <p>Please note that smoking and vaping is not permitted on the Forestry Farm Park grounds.</p>

            <h2>Map</h2>
            <Map />

            <h2>Address</h2>
            {websiteLink}
            {addressComponents.map((component) => (
                <div key={component}>{component}</div>
            ))}

            <h2>Parking</h2>
            <p>Parking fees at the Forestry Farm will be covered, and vehicles can be left on the premises overnight if necessary.</p>
        </div>
    );
};

export default Venue;