import React from 'react';
import { addressComponents } from './Venue';
import CustomHeader from '../Header';

const Programme = () => {
    const programme = [
        {
            title: "Ceremony",
            startTime: "3:00 pm",
            location: "Wedding Garden",
            gpsCoords: [50, -100],
            description: "Ceremony will be moved indoors if the weather is too cold or too wet.",
            exended: ""
        },
        {
            title: "Reception",
            startTime: "5:30 pm",
            location: "Hall",
            gpsCoods: [50, -100],
            description: "Dinner, drinks, and dancing",
                    },
        {
            title: "Supper",
            startTime: "6:15 pm",
            location: "Hall",
            description: "Mains will be citrus & fresh herb chicken and pork tenderloin with bourbon-rosemary sauce, or vegan mushroom shepherd's pie (on request). There will be a $3 cash bar."

        }
    ];
    return (
        <div id="home">
            <CustomHeader />
            <div className='tab-inner-content'>
                <h1>May 4, 2024</h1>
                <h2>At the Saskatoon Forestry Farm</h2>
                <h3>Here's what to know for the day of:</h3>
                <ul>
                    <li>There may be a line to get in, please arrive early if possible.</li>
                    <li>Parking is available by the Hall. <a href="https://maps.app.goo.gl/ykbkUgYBuj66ed1j6">Location on map</a></li>
                    <li>Drive safe! Vehicles can be left overnight. We will have a sign-up sheet to notify maintenance at the reception.</li>
                </ul>
                <h4 style={{color: "#005f75", textDecoration: "underline"}}>Address</h4>
                <p>{addressComponents.map((a) => <div key={a}>{a}</div>)}</p>
                {programme.map((item) => {
                    return (
                        <div className='programme-item'>
                            <h4 style={{color: "#005f75", textDecoration: "underline"}}>{item.title}</h4>
                            <p>{item.startTime} at the {item.location}</p>
                            <p style={{fontStyle: "italic"}}>{item.description}</p>
                            {/* <p style={{fontStyle: "italic"}}>{item.extended}</p> */}
                        </div>
                    );
                })}
                {/* supper is at 6:15 */}
                {/* bar menu */}

                {/* <h4 style={{color: "#005f75", textDecoration: "underline"}}>Parking</h4> */}
                
            </div>
        </div>
    );
};

export default Programme;