import React from 'react';
import './App.css';
import { ConfigProvider, Affix, Anchor } from 'antd';
import Home from './tabs/Home';
import Registry from './tabs/Registry';
import RSVP from './tabs/RSVP';
import Venue from './tabs/Venue';
import Pictures from './tabs/Pictures';
import Accommodations from './tabs/Accommodations';
import Programme from './tabs/Programme';

export const Features = {
  IS_RSVP_SETUP: true,
  IS_REGISTRY_SETUP: false,
}

function App() {
  const customTheme = {
    token: {
      colorPrimary: '#52438d'
    }
  }

  const showProgramme = true;

  let homeComponent = {
      key: 'home',
      component: <Home />,
      emoji: "💖",
      text: "",
    };

    if (showProgramme) {
      homeComponent = {
      key: 'home',
      component: <Programme />,
      emoji: "💖",
      text: "",
    }
    }

  const content = [
    homeComponent,
    {
      key: 'venue',
      component: <Venue />,
      emoji: "🏰",
      text: "Venue",
    },
    {
      key: "accommodations",
      component: <Accommodations />,
      emoji: "🛎️",
      text: "Accommodations",
    },
    // {
    //   key: "rsvp",
    //   component: <RSVP />,
    //   emoji: "📜",
    //   text: "RSVP"
    // },
    {
      key: 'registry',
      component: <Registry/>,
      emoji: "🛶",
      text: "Registry",
    },
    {
      key: 'pictures',
      component: <Pictures />,
      emoji: "📷",
      text: "Pictures"
    }
  ];

  const anchorItems = content.map((item) => {
    return ({
      key: item.key,
      href: `#${item.key}`,
      title: <div className='menu-label'>{item.emoji}<span className='menu-text'>{item.text}</span></div>      
    })
  })

  const offsetTop = 0; // h2 height 39 + header height 190
  return (
    <ConfigProvider 
      theme={customTheme}
    >
        {content.map((c, index) => {
          const isLast = index === content.length -1;
          const style = isLast ? {paddingBottom: "75px"} : {};
          return (<div className='tab-container' style={style}>{c.component}</div>)
        })}

        <Affix offsetBottom={0}>
          <Anchor direction='horizontal' items={anchorItems} offsetTop={offsetTop}/>
        </Affix>
      
    </ConfigProvider>
  );
}

export default App;