import React from 'react';

const CustomHeader = () => {
    return (
        <div id='header'>
            Tara & Matt
            <div id='header-subtitle'>
                May 4th, 2024
            </div>
        </div>
    );
};

export default CustomHeader;