import React from "react";

const Accommodations = () => {
    return <div className="tab-inner-content" id="accommodations">
        <h1>Accommodations</h1>
        <h2>Looking for somewhere to stay in Saskatoon?</h2>
        <p>We have a block booking at the Parktown Hotel</p>
        <p>Use the following information- the standard room is $139 and you will receive an $8 breakfast voucher to use for breakfast and complimentary surface parking</p>
        <ul>
            <li>Block Name: Epp and Heagy Wedding</li>
            <li>Group Code: 2405EPPHEAGY</li>
            <li>Group ID: 357704</li>
        </ul>
        <h3>Contact information:</h3>
        <p>
            Website: <a href="https://www.parktownhotel.com/">https://www.parktownhotel.com/</a>
            <br />
            (To get the group rate, click "Add Code" when booking online, select "Group Attendee" and enter the "Group Code" above)
        </p>
        <p>
            Phone: 306-244-5564 or 1-800-667-399
        </p>
        
    </div>
};

export default Accommodations;