import React from 'react';

const Registry = () => {
    const registryUrl = 'https://www.myregistry.com/giftlist/taramatt2024';
    return (
        <div className='tab-inner-content' id='registry'>
            <h1>Registry</h1>
            <p>If you would like to purchase us a gift, feel free to check out our registry:&nbsp;
                <a href={registryUrl} 
                target="_blank" 
                rel="noopener noreferrer"
                >{registryUrl}</a>
            </p>
            <p>Contributions towards our honeymoon in Japan are also appreciated.</p>
        </div>
    );
};

export default Registry;