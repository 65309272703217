import React from "react";
import { Carousel, Image } from 'antd';
import img1 from "../images/87106738_10157800289520907_6335089728106266624_o.jpg";
import img2 from "../images/IMG_2127.jpg";
import img3 from "../images/IMG_2216.jpg";
import img4 from "../images/IMG_2348.jpg";
import img5 from "../images/IMG_3114.jpg";
import img6 from "../images/IMG_E1052.jpg";
import img7 from "../images/PXL_20230730_181214283.jpg";


const Pictures = () => {
    const pictures = [img1, img2, img3, img4, img5, img6, img7];
    return <div className="tab-inner-content" id="pictures">
        <h1>Pictures!</h1>
        <Carousel autoplay dotPosition="top" autoplaySpeed={5000} infinite>
        {pictures.map((pic, index) => {
            return <img src={pic} key={`image-${index}`}
            className="carousel-images"
            />
        })}
        </Carousel>
    </div>;
}

export default Pictures;