import { Status, Wrapper,  } from "@googlemaps/react-wrapper";
import { LoadingOutlined, FrownOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useEffect, useRef } from "react";

const Map = () => {
    const lat = 52.1568682;
    const lng = -106.5816730;
    const zoom = 15;

    const RenderMap = () => {
        const ref = useRef(null);
        const center = {lat: lat, lng: lng}
        useEffect(() => {
            const LeGoogleMap = (window as any).google.maps.Map ?? (() => <div>lol</div>);
            new LeGoogleMap(ref.current, {
                center, zoom
            });
        });

        return <div ref={ref} id="map" style={{height:"400px"}} />;
    }

    const render = (status: Status) => {
        switch (status) {
            case Status.LOADING:
                return <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
            case Status.FAILURE:
                return <FrownOutlined />
            case Status.SUCCESS:
                return <RenderMap />;
        }
    }
    return (
        <div className='map-container'>
            <Wrapper apiKey="AIzaSyCjpNIyOHEafdlykWk7MJYaORXyHrhx_Xc" render={render} />
        </div>
    );
};

export default Map;