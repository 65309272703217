import React from 'react';
import { addressComponents } from './Venue';
import CustomHeader from '../Header';

const Home = () => {
    const programme = [
        {
            title: "Ceremony",
            startTime: "3:00 pm",
            location: "Wedding Garden",
            gpsCoords: [50, -100],
            description: "",
            exended: ""
        },
        {
            title: "Reception",
            startTime: "5:30 pm",
            location: "Hall",
            gpsCoods: [50, -100],
            description: "Dinner, drinks, and dancing",
            extended: "Mains will be citrus & fresh herb chicken and pork tenderloin with bourbon-rosemary sauce, or vegan mushroom shepherd's pie (on request). There will be a $3 cash bar."
        }
    ];
    return (
        <div id="home">
            <CustomHeader />
            <div className='tab-inner-content'>
                <h1>May 4, 2024</h1>
                <h2>At the Saskatoon Forestry Farm</h2>
                <p>{addressComponents.map((a) => <div key={a}>{a}</div>)}</p>
                {programme.map((item) => {
                    return (
                        <div className='programme-item'>
                            <h3>{item.title}</h3>
                            <p>{item.startTime} at the {item.location}</p>
                            <p>{item.description}</p>
                            <p style={{fontStyle: "italic"}}>{item.extended}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Home;